<template>
  <div class="px-1">
    <template v-if="egress_profile_matter_id">
      <MatterProgram
        :egress_profile_matter_id="egress_profile_matter_id"
        :allows_crud="allows_crud"
      ></MatterProgram>
    </template>
    <template v-else> Ha ocurrido un error... </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "MatterProgramView",
  components: {
    MatterProgram: () => import("./MatterProgram"),
  },
  data() {
    return {
      egress_profile_matter_id: Number(this.$route.params.matter_id),
      to_print: false,
    };
  },
  methods: {},
  computed: {
    ...mapGetters({
      user: "getUser",
      user_position: names.USER_POSITION,
      egressProfiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      matters: names.MATTERS,
    }),
    egressProfile() {
      if (!this.matter) return [];
      return this.egressProfiles.find(
        (x) => x.id == this.matter.egress_profile
      );
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    allows_crud() {
      // (!this.matter.is_closed || !this.oldMatter.is_closed) &&
      if (
        this.career &&
        this.egressProfile &&
        !this.egressProfile.is_closed &&
        this.matter &&
        this.oldMatter &&
        ((this.user_position &&
          [1, 2].includes(this.user_position.position) &&
          [1, 2].includes(this.user.groups[0])) ||
          this.user.is_superuser ||
          this.$hasObjectPermission(
            "mesh.faculty",
            "change",
            this.career.faculty
          ) ||
          this.$hasObjectPermission(
            "mesh.career",
            "change",
            this.egressProfile.career
          ))
      ) {
        const has_permission = this.$hasPermissions("teaching.change_matter");
        if (has_permission) {
          this.$store.commit(names.MUTATE_VISIBILITY_CRUD, true);
          return has_permission;
        } else {
          return false;
        }
      } else {
        this.$store.commit(names.MUTATE_VISIBILITY_CRUD, false);
        return false;
      }
    },
    matter() {
      return this.egress_profiles_matters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
    oldMatter() {
      if (!this.matter) return null;
      return this.matters.find((x) => x.id == this.matter.matter);
    },
  },
  created() {
    this.$store
      .dispatch(
        names.FETCH_EGRESS_PROFILE_MATTER,
        this.egress_profile_matter_id
      )
      .then((response) => {
        this.$store
          .dispatch(names.FETCH_EGRESS_PROFILE, response.egress_profile)
          .then((egress_profile) => {
            this.$store.dispatch(names.FETCH_CAREER, egress_profile.career);
            this.$store.dispatch(names.FETCH_EGRESS_PROFILES_MATTERS, {
              egress_profile_id: egress_profile.id,
            });
          });
      });
  },
  mounted() {},
};
</script>

<style scoped>
</style>